<template>
  <div class="pa-0">
    <hb-form
      label="Address and Contact Details"
      v-if="!isEditMode"
    >
      <div>
        <span>
          {{ selectedLabel }}
        </span>
        <br />
        <div
          v-if="facilityID && companyDefaultDetails && selected === 'isCompany'"
        >
          <span>{{ companyDefaultDetails.name }}</span>
          <br />
          <span v-if="companyDefaultDetails?.Phone?.phone">{{
            companyDefaultDetails.Phone | displayPhone | formatPhone
          }}</span>
          <span v-if="companyDefaultDetails.email">
            {{ " | " + companyDefaultDetails.email }}</span
          >
          <br />
          <span v-if="companyDefaultDetails.Address">{{
            companyDefaultDetails.Address | formatAddress
          }}</span>
        </div>

        <div v-if="facilityID && propertyDetails && selected === 'isProperty'">
          <span>{{ propertyDetails.name }}</span>
          <br />
          <span v-if="propertyDetails?.Phone?.phone">{{
            propertyDetails.Phone | displayPhone | formatPhone
          }}</span>
          <span v-if="propertyDetails.email">
            {{ " | " + propertyDetails.email }}</span
          >
          <br />
          <span v-if="propertyDetails.Address">{{
            propertyDetails.Address | formatAddress
          }}</span>
        </div>
      </div>
    </hb-form>

    <hb-form
      label="Name"
      v-if="selected === 'isCustom' && !isEditMode"
    >
      {{ localCustomDetails.name }}
    </hb-form>

    <hb-form
      label="Email"
      v-if="selected === 'isCustom' && !isEditMode"
    >
      {{ localCustomDetails.email }}
    </hb-form>

    <hb-form
      label="Phone"
      v-if="selected === 'isCustom' && !isEditMode"
    >
      {{ localCustomDetails.Phone | displayPhone | formatPhone }}
    </hb-form>

    <hb-form
      label="Address"
      v-if="selected === 'isCustom' && !isEditMode"
    >
      {{ formatCustomAddress }}
    </hb-form>

    <hb-form
      v-if="isEditMode"
      label="Address and Contact Details"
    >
      <v-row>
        <v-col cols="12">
          <hb-radio-group v-model="selected">
            <hb-radio
              v-if="facilityID"
              value="isCompany"
              label="Corporate Default"
            >
              <template v-slot:description>
                <div v-if="facilityID && companyDefaultDetails">
                  <span>{{ companyDefaultDetails.name }}</span>
                  <br />
                  <span v-if="companyDefaultDetails?.Phone?.phone">{{
                    companyDefaultDetails.Phone | displayPhone | formatPhone
                  }}</span>
                  <span v-if="companyDefaultDetails.email">
                    {{ " | " + companyDefaultDetails.email }}</span
                  >
                  <br />
                  <span v-if="companyDefaultDetails.Address">{{
                    companyDefaultDetails.Address | formatAddress
                  }}</span>
                </div>
              </template>
            </hb-radio>
            <hb-radio
              value="isProperty"
              label="Property Information"
              description="Address and contact details of the associated property"
            >
              <template v-slot:description>
                <div v-if="facilityID && propertyDetails">
                  <span>{{ propertyDetails.name }}</span>
                  <br />
                  <span v-if="propertyDetails?.Phone?.phone">{{
                    propertyDetails.Phone | displayPhone | formatPhone
                  }}</span>
                  <span v-if="propertyDetails.email">
                    {{ " | " + propertyDetails.email }}</span
                  >
                  <br />
                  <span v-if="propertyDetails.Address">{{
                    propertyDetails.Address | formatAddress
                  }}</span>
                </div>
              </template>
            </hb-radio>
            <hb-radio
              value="isCustom"
              label="Custom"
            />
          </hb-radio-group>
        </v-col>
      </v-row>
    </hb-form>

    <hb-form
      label="Name"
      v-if="selected === 'isCustom' && isEditMode"
    >
      <HbTextField
        :id="'name'"
        :name="'name'"
        v-validate="'required|max:256'"
        v-model.lazy="localCustomDetails.name"
        label="Name"
        single-line
        data-vv-as="Name"
        :error="errors.has('name')"
      ></HbTextField>
    </hb-form>

    <hb-form
      label="Email"
      v-if="selected === 'isCustom' && isEditMode"
    >
      <HbTextField
        :id="'email'"
        :name="'email'"
        v-validate="'required|max:256|email'"
        v-model.lazy="localCustomDetails.email"
        label="Email"
        single-line
        data-vv-as="Email"
        :error="errors.has('email')"
      ></HbTextField>
    </hb-form>

    <hb-form
      label="Phone"
      v-if="selected === 'isCustom' && isEditMode"
    >
      <v-row class="ma-0 pa-0">
        <v-col
          cols="12"
          class="hb-z-index-99999 ma-0 pa-0 hb-forms-max-width-498"
        >
          <HbSelect
            :items="phone_types"
            v-validate="'required'"
            v-model.lazy="localCustomDetails.Phone.type"
            label="Type"
            single-line
            :id="'phone_type'"
            data-vv-as="phone type"
            :name="'phone_type'"
            :error="errors.has('phone_type')"
          ></HbSelect>
        </v-col>
        <v-col
          cols="3"
          class="hb-z-index-99999 ma-0 pa-0 mt-3 pr-3"
        >
          <HbAutocomplete
            :items="country_codes"
            :clearable="false"
            v-model="localCustomDetails.Phone.code"
            label="Code"
            single-line
            :id="'phone_code'"
            data-vv-scope="general"
            data-vv-as="phone code"
            :name="'phone_code'"
            @change="
              checkValidPhone(
                localCustomDetails.Phone.code,
                localCustomDetails.Phone.phone
              )
            "
            @focusout="
              checkValidPhone(
                localCustomDetails.Phone.code,
                localCustomDetails.Phone.phone
              )
            "
            v-validate="{ required: true, max: 16, numeric: true }"
            :error="errors.has('phone_code') || isInvalidPhone"
          >
            <template v-slot:selection="data"> +{{ data.item }} </template>
            <template v-slot:item="data"> +{{ data.item }} </template>
          </HbAutocomplete>
        </v-col>
        <v-col
          cols="4"
          class="hb-z-index-99999 ma-0 pa-0 mt-3 pr-3"
        >
          <HbTextField
            v-model="localCustomDetails.Phone.phone"
            label="Number"
            single-line
            :id="'phone_number'"
            data-vv-as="phone number"
            :name="'phone_number'"
            @change="
              checkValidPhone(
                localCustomDetails.Phone.code,
                localCustomDetails.Phone.phone
              )
            "
            @focusout="
              checkValidPhone(
                localCustomDetails.Phone.code,
                localCustomDetails.Phone.phone
              )
            "
            v-validate="{ required: true, max: 16, numeric: true }"
            :error="errors.has('phone_number') || isInvalidPhone"
          ></HbTextField>
        </v-col>
      </v-row>
    </hb-form>

    <hb-form
      label="Address"
      v-if="selected === 'isCustom' && isEditMode"
    >
      <div>
        <v-row>
          <v-col cols="12">
            <HbAutocomplete
              :items="countriesListMixin"
              v-validate="'required'"
              v-model="localCustomDetails.Address.country"
              label="Country"
              single-line
              :id="'country'"
              data-vv-as="country"
              :name="'country'"
              :error="errors.has('country')"
              @change="setCountry"
            >
              <template v-slot:selection="data">
                {{ data.item }}
              </template>
              <template v-slot:item="data">
                {{ data.item }}
              </template>
            </HbAutocomplete>
          </v-col>
          <v-col cols="12">
            <HbTextField
              :id="'address'"
              :name="'address'"
              v-validate="'required|max:256'"
              v-model.lazy="localCustomDetails.Address.address"
              label="Street"
              single-line
              data-vv-as="Street"
              :error="errors.has('address')"
            ></HbTextField>
          </v-col>
          <v-col cols="12">
            <HbTextField
              :id="'address2'"
              :name="'address2'"
              v-model.lazy="localCustomDetails.Address.address2"
              v-validate="'max:45'"
              label="Suite / Apt"
              single-line
              data-vv-as="Suite / Apt"
              :error="errors.has('address2')"
            ></HbTextField>
          </v-col>
          <v-col cols="12">
            <HbTextField
              :id="'address_zip'"
              :name="'address_zip'"
              v-validate="'required'"
              v-model.lazy="localCustomDetails.Address.zip"
              label="Zip"
              single-line
              data-vv-as="zip"
              :error="errors.has('address_zip')"
            ></HbTextField>
          </v-col>
          <v-col cols="12">
            <HbAutocomplete
              v-model="localCustomDetails.Address.city"
              :items="cityListMixin[0]"
              single-line
              v-validate="'max:45|required'"
              data-vv-name="city"
              data-vv-as="city"
              :error="errors.has('city')"
              item-text="name"
              item-value="value"
              placeholder="City"
            />
          </v-col>
          <v-col cols="12">
            <HbAutocomplete
              v-model="localCustomDetails.Address.state"
              :items="stateListMixin[0] || []"
              single-line
              v-validate="'max:45|required'"
              data-vv-name="state"
              data-vv-as="state"
              :error="errors.has('state')"
              item-text="name"
              item-value="value"
              placeholder="State"
            />
          </v-col>
        </v-row>
      </div>
    </hb-form>

    <v-divider> </v-divider>

    <v-row>
      <v-col
        v-if="isEditMode"
        cols="12"
        class="pa-1 mt-1 pr-5 d-flex flex-row-reverse align-items-center"
      >
        <hb-btn
          color="primary"
          class="pa-2"
          @click="handleEmittedSave"
          >Save</hb-btn
        >
        <hb-link
          class="pa-2 pt-2 align-items-center"
          @click="handleEmittedCancel()"
          >Cancel</hb-link
        >
      </v-col>
      <v-col
        v-if="!isEditMode"
        cols="12"
        class="pa-2 ma-2 mt-1 pr-5 d-flex flex-row-reverse"
      >
        <hb-btn
          class="pr-2"
          color="secondary"
          :disabled="isLoading($options.name)"
          :loading="isLoading($options.name)"
          v-if="hasPermission('send_payment_to')"
          @click="handleEditMode"
          >Edit</hb-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import { notificationMixin } from "../../..//mixins/notificationMixin.js";
import { countryStateCityMixin } from "../../../mixins/countryStateCityMixin.js";
import { validateEmailPhoneMixin } from "../../../mixins/validateEmailPhoneMixin";
import ADDRESS from "@/constants/address.js";
import PHONE from "@/constants/phone.js";
import { parsePhoneNumber } from "libphonenumber-js";

export default {
  name: "SentPaymentToSettings",
  mixins: [notificationMixin, countryStateCityMixin, validateEmailPhoneMixin],
  data() {
    return {
      isEditMode: "",
      active: "",
      selected: "",
      localCustomDetails: {
        Address: {
          address: "",
          address2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        Phone: {
          type: "",
          phone: "",
          code: "",
        },
        email: "",
        name: "",
      },
      isInvalidPhone: false,
      companyDefaultDetails: {},
      propertyDetails: {},
      settings: [],
      phone_types: PHONE.TYPES,
      country_codes: PHONE.COUNTRY_CODES,
      states: ADDRESS.STATES,
      codSettingsCache: [],
      showLoading: false,
    };
  },
  async created() {
    this.setCountriesListEvent();
    await this.getSettings();
  },
  filters: {
    displayPhone(phone) {
      return `${phone.country_code ? phone.country_code : ""} ${phone.phone}`;
    },
  },
  props: ["facilityID", "toggleSwitched", "details"],
  components: {},
  computed: {
    ...mapGetters({
      hasPermission: "authenticationStore/rolePermission",
    }),
    selectedLabel() {
      const labels = {
        isCompany: "Corporate Default",
        isProperty: "Property Information",
        isCustom: "Custom",
      };
      return labels[this.selected] || "Property Information";
    },
    formatCustomAddress() {
      const toRetrun = Object.values(this.localCustomDetails.Address)
        .filter(Boolean)
        .join(" ");
      return toRetrun;
    },
    showAddressOnReadMode() {
      let toRetrun = "";
      if (this.details) {
        const {
          isCompany,
          isCustom,
          isProperty,
          customDetails,
          companyDefaultDetails,
          propertyDetails,
        } = this.details;
        if (isCompany && companyDefaultDetails?.Address) {
          toRetrun = companyDefaultDetails.Address;
        } else if (isCustom && customDetails?.Address) {
          toRetrun = customDetails.Address;
        } else if (isProperty && propertyDetails?.Address) {
          toRetrun = propertyDetails.Address;
        }
      }
      return toRetrun;
    },
  },
  methods: {
    clearAllEditing() {
      this.localCustomDetails = {
        Address: {
          address: "",
          address2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        Phone: {
          type: "",
          phone: "",
          code: "",
        },
        email: "",
        name: "",
      };
      this.isEditMode = false;
    },
    setCountry() {
      this.localCustomDetails.Address.state = "";
      this.localCustomDetails.Address.city = "";
      this.setStatesListEvent(this.localCustomDetails.Address.country, 0);
      this.setCountryCitiesListEvent(
        this.localCustomDetails.Address.country,
        0
      );
    },
    setState() {
      this.localCustomDetails.Address.city = "";
      this.setCitiesListEvent(
        this.localCustomDetails.Address.country,
        this.localCustomDetails.Address.state
      );
    },
    setCityList(payload) {
      const { event, country, state, cityList } = payload;
      const toValidate = payload?.toValidate || false;
      const { value, dataset } = event.target;
      if (!value) return;
      if (toValidate) {
        const name = `${dataset.vvScope}.${dataset.vvName}`;
        const isSpecialCharacter = this.validateCityName(value);
        const cityValidation = this.errors.items.indexOf(name);

        this.errors.items.splice(cityValidation);
        if (isSpecialCharacter) {
          return this.errors.add({
            field: name,
            msg: "Please add a valid city name.",
          });
        }
      }
      const cityName = value.toString();
      const cityData = cityList;
      const isCityExists = cityData.some(
        (city) => city.toLowerCase() === cityName.toLowerCase()
      );
      if (isCityExists) return;
      const countryData = this.countriesListMixinRaw.find(
        (item) => item.name === country
      );
      this.addMissingCity({
        country_code: countryData.isoCode,
        state_code: state,
        city: value,
      });
    },
    checkValidPhone(code, number) {
      let phone = "+" + code + number;
      try {
        let parsedPhoneNumber = parsePhoneNumber(phone);
        this.isInvalidPhone = !parsedPhoneNumber.isValid();
      } catch (err) {
        this.isInvalidPhone = true;
      }
    },
    handleEmittedCancel() {
      this.getSettings();
      this.isEditMode = false;
    },
    handleEditMode() {
      this.isEditMode = true;
    },
    async handleEmittedSave() {
      try {
        this.$validator.validateAll().then(async (valid) => {
          if (!valid || this.isInvalidPhone) {
            this.list = true;
            this.description =
              "There are errors in your form, correct them before continuing.";
            this.type = "error";
            this.snackbar = true;
          } else {
            await this.saveSetting();
            this.active = "saved";
            this.showMessageNotification({
              type: "success",
              description: `Send Payment To setting have been saved.`,
            });
            this.isEditMode = false;
          }
        });
      } catch (err) {
        this.showMessageNotification({
          type: "error",
          description: `Error: ${err}`,
        });
        this.isEditMode = false;
      }
    },
    getSettings() {
      if (this.details) {
        const {
          isCompany,
          isCustom,
          isProperty,
          customDetails,
          companyDefaultDetails,
          propertyDetails,
        } = this.details;
        if (this.facilityID) {
          this.companyDefaultDetails = companyDefaultDetails;
          this.propertyDetails = propertyDetails;
          if (isCompany) {
            this.selected = "isCompany";
          } else if (isCustom) {
            this.selected = "isCustom";
          } else if (isProperty) {
            this.selected = "isProperty";
          }
        } else {
          if (isCustom) {
            this.selected = "isCustom";
          } else if (isProperty) {
            this.selected = "isProperty";
          }
        }
        if (isCustom) {
          this.localCustomDetails = {
            ...this.localCustomDetails,
            ...customDetails,
          };
          if (this.localCustomDetails?.Address?.country) {
            this.setStatesListEvent(this.localCustomDetails.Address.country, 0);
            this.setCountryCitiesListEvent(
              this.localCustomDetails.Address.country,
              0
            );
          }
        }
      }
    },
    async saveSetting() {
      const settings = {
        sendPaymentTo: {
          enabled: true,
          details: {
            isProperty: this.selected === "isProperty",
            isCustom: this.selected === "isCustom",
            ...(this.facilityID && {
              isCompany: this.selected === "isCompany",
            }),
            customDetails:
              this.selected === "isCustom" ? this.localCustomDetails : {},
          },
        },
      };
      this.$emit("updateSendPaymentToSetting", { settings });
    },
  },
  watch: {
    facilityID() {
      this.clearAllEditing();
      this.getSettings();
    },
    toggleSwitched() {
      this.getSettings();
    },
    details: {
      handler(newVal) {
        this.getSettings();
      },
      deep: true
    }
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}
</style>
