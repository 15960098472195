<template>
  <div class="settings-billing">
    <div v-if="isPropertyLevel">
      <HbPropertySelector
        id="facility"
        :items="facilityList"
        item-text="name"
        item-value="id"
        v-validate="'required'"
        v-model="property_id"
        data-vv-name="facility"
        data-vv-as="Property"
        :error="errors.has('facility')"
      />
    </div>

    <div class="mr-6" :class="{'pt-1' : !isPropertyLevel}">
      <v-expansion-panels
        v-if="property_id || !isPropertyLevel"
        v-model="panel"
        multiple
        :disabled="(isPropertyLevel && !property_id) ? true : false"
      >
        <hb-expansion-panel :top-margin="false">
          <template v-slot:title>
            Transactional
          </template>
          <template v-slot:content>
            <transactional
              :level="level"
              :facilityID.sync="property_id"
            >
            </transactional>
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel key="reversals">
          <template v-slot:title>
            Reversals
          </template>
          <template v-slot:content>
            <reversals
              :level="level"
              :facilityID.sync="property_id"
            >
            </reversals>
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel :key="property_id">
          <template v-slot:title>
            Invoice Notification
          </template>
          <template v-slot:content>
            <pdf-statement-attachment :facilityID.sync="property_id" />
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel key="close_of_day" :left-cols="4" :right-cols="8">
          <template v-slot:title>
            Close of Day
            <hb-tooltip>
              <template v-slot:item>
                <HbIcon small class="mr-1" mdi-code="mdi-information" />
              </template>
              <template v-slot:body>
                Match recorded payments with your cash drawer daily
              </template>
            </hb-tooltip>
          </template>
          <template v-slot:actions>
            <div @click.stop="checkForPermission('close_of_day_config', 'Close of Day')">
              <hb-switch :disabled="!isCODToggleEnable" class="mx-2" v-model="tempCODSwitchValue"
                @change="handleCODSwitchChange"/>
            </div>
          </template>
          <template v-slot:content>
            <close-of-day-settings :facilityID.sync="property_id" :toggleSwitched="isCodToggleUpdated" />
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel key="send_payment_to" :left-cols="4" :right-cols="8">
          <template v-slot:title>
            Send Payment To
            <hb-tooltip>
              <template v-slot:item>
                <HbIcon small class="mr-1" mdi-code="mdi-information" />
              </template>
              <template v-slot:body>
                When turned ON: Users can select an address and contact details to display.
                When turned OFF: The invoice will automatically display the property address and contact details.
              </template>
            </hb-tooltip>
          </template>
          <template v-slot:actions>
            <div @click.stop="checkForPermission('send_payment_to', 'Send Payment To')">
              <hb-switch :disabled="!isSPTToggleEnable || isLoading($options.name)" class="mx-2" v-model="tempSPTSwitchValue"
                @change="handleSPTSwitchChange"/>
            </div>
          </template>
          <template v-slot:content>
            <div>
            <v-row v-if="isLoading($options.name)" justify="center" align="center">
              <v-col class="text-center ma-12">
                <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
              </v-col>
            </v-row>
            <send-payment-to-settings :key="sendPaymentToKey" v-else-if="tempSPTSwitchValue" :facilityID.sync="property_id" :toggleSwitched="isSPTToggleUpdated" :details="settings.sendPaymentTo?.details" 
            @updateSendPaymentToSetting="updateSendPaymentToSetting" />
          </div>
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel v-if="!isPropertyLevel">
          <template v-slot:title>
            Financial Reporting
            <hb-tooltip max-width="300">
              <template v-slot:item>
                <HbIcon small class="mr-1" mdi-code="mdi-information" />
              </template>
              <template v-slot:body>
                Set how year-to-date numbers are calculated for your reports. By default, the Calendar Year starts in January, but you can choose Fiscal Year to define a custom start month. All reports will reflect this selection for year-to-date data
              </template>
            </hb-tooltip>
          </template>
          <template v-slot:content>
            <financial-reporting 
              :fiscalYearMonth="settings.fiscalYearMonth"
              @updateFiscalYearMonth="updateFiscalYearMonth"
              @save="save"
            >
            </financial-reporting>
          </template>
        </hb-expansion-panel>
      </v-expansion-panels>

      <hb-empty-state
        v-else
        message="Please select a Property to continue."
      >
      </hb-empty-state>
      <hb-modal v-model="openCloseOfDayModal" show-help-link v-if="openCloseOfDayModal" size="medium" title="Change close of day" @close="cancelCODChange" confirmation>
        <template v-slot:content>
          <div class="py-4 px-6">
            Are you sure you want to disable the Close of Day feature?
            <br /><br />
            This action will reset the values added below. All unsettled transactions will be settled based on created date.
          </div>
        </template>
        <template v-slot:actions>
          <hb-btn color="destructive" @click="updateCODStatus">Save</hb-btn>
        </template>
    </hb-modal>
    <hb-modal v-model="openSendPaymentToModal" show-help-link v-if="openSendPaymentToModal" size="medium" title="Change Send Payment To" @close="cancelSPTChange" confirmation>
        <template v-slot:content>
          <div class="py-4 px-6">
            Are you sure you want to disable the Send Payment feature?
          </div>
        </template>
        <template v-slot:actions>
          <hb-btn :disabled="isLoading($options.name)" :loading="isLoading($options.name)" color="destructive" @click="updateSPTStatus">Save</hb-btn>
        </template>
    </hb-modal>
    </div>
  </div>
  <!-- <div class="section-content">
    <div class="primary-section-content">
      <div>
        <status @resetStatus="errorClear($options.name)" v-if="(errorHas($options.name) || errors.has('offset') || errors.has('taxrate')) && errorMsg !== ''" :message="errorMsg" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

        <v-card outlined style="border-radius: 0px;">
          <v-card-text class="pl-3 py-0 pr-0 hide-overflow">
            <v-row class="mr-0">
              <v-col class="pa-0" cols="3">
                <div class="billing-label">
                  <div class="pa-4 pl-6">
                    <span class="font-weight-medium hb-default-font-size">Account Auto-Charge Offset</span>
                  </div>
                </div>
              </v-col>
              <v-col class= "py-0" cols="9">
                <div class="pt-4 pb-7 px-3">
                  <p class="hb-text-light hb-default-font-size">Enter number of days before invoice due date you want to process payments.</p>
                  <div style="display:inline-flex">
                    <v-text-field
                      v-model="settings.invoiceChargeOffset"
                      v-validate="'required|integer|min:0|max:45'"
                      class="w-input"
                      style="width: 90px;"
                      id="offset"
                      name="offset"
                      :error="errors.has('offset')"
                    >
                     :error-messages="errors.first('offset')" -->
  <!-- </v-text-field>
                    <p class="mb-0 pt-2 ml-2">Days</p>
                  </div>

                </div>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="mr-0">
              <v-col class="pa-0" cols="3">
                <div class="billing-label">
                  <div class="pa-4 pl-6">
                    <span class="font-weight-medium hb-default-font-size">Enter tax rate</span>
                  </div>
                </div>
              </v-col>
              <v-col class= "py-0" cols="9">
                <div class="pt-4 pb-7 px-3">
                  <p class="hb-text-light mb-0 hb-default-font-size pb-4">Enter the sales tax rate for merchandise that requires tax. If you are a QuickBooks user, this rate can be overridden by the rate set up in that application.</p>
                  <div style="display:inline-flex">
                    <v-text-field
                      v-model="settings.taxRate"
                      v-validate="'required|decimal:2|max:45'"
                      class="w-input"
                      style="width: 95px;"
                      placeholder="7.25"
                      id="taxrate"
                      name="taxrate"
                      :error="errors.has('taxrate')"
                    >
                    </v-text-field>
                    <p class="mb-0 pt-2 ml-2">%</p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="py-3">
            <v-spacer></v-spacer>
            <a
              class="mr-2"
              @click="cancel"
            >
              Cancel
            </a>
            <hb-btn
              class="mr-2"
              color="primary"
              :disabled="isLoading($options.name)"
              @click="save"
              :loading="isLoading($options.name)"
            >
              Send
            </hb-btn>
          </v-card-actions>
        </v-card>
      </div>
  </div>
  </div> -->
</template>

<script type="text/babel">
import Transactional from "./Transactional/Index.vue";
import Reversals from "./Reversals/Index.vue";
import PdfStatementAttachment from "./PdfStatementAttachment/Index.vue";
import CloseOfDaySettings from "./CloseOfDaySettings/Index.vue";
import SendPaymentToSettings from "./SendPaymentToSettings/Index.vue";
import Status from "../includes/Messages.vue";
import api from "../../assets/api.js";
import settingsTabMixin from '../../mixins/settingsTabMixin.js';
import { mapGetters, mapActions, mapMutations } from "vuex";
import { notificationMixin } from "../../mixins/notificationMixin.js";
import FinancialReporting from "./FinancialReporting/Index.vue";

export default {
  name: "BillingSettings",
  mixins:[settingsTabMixin, notificationMixin],
  props: ['level'],
  data() {
    return {
      panel: [],
      category: "billing",
      settings: {
        invoiceChargeType: "$",
        defaultDays: "",
        lockoutDays: "",
        auctionDays: "",
        invoiceSendDay: "",
        taxRate: "",
        invoiceChargeOffset: "",
        fiscalYearMonth: "",
        closeOfDay: false,
        sendPaymentTo: {
          enabled: false,
          details: {},
        },
      },
      lateTypes: ["$", "%"],
      errorMsg: "",
      openCloseOfDayModal: false,
      tempCODSwitchValue: false,
      isCodToggleUpdated: null,
      sendPaymentToKey: 0,
      tempSPTSwitchValue: false,
      openSendPaymentToModal: false,
      isSPTToggleUpdated: null,
      property_id: null
    };
  },
  components: {
    Transactional,
    Reversals,
    Status,
    SendPaymentToSettings,
    PdfStatementAttachment,
    FinancialReporting,
    CloseOfDaySettings,
  },
  async created() {
    await this.fetchData();
  },
  computed:  {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      hasPermission: 'authenticationStore/rolePermission',
      isCorporateCODEnabled: 'closeOfDayStore/isCorporateCODEnabled',
      isCorporateSPTEnabled: 'closeOfDayStore/isCorporateSPTEnabled',
      defaultProperty: "propertiesStore/defaultProperty"
    }),
    isPropertyLevel() {
      return this.level === 'Property' ? true : false;
    },
    isCODToggleEnable() {
      let isEnabled = this.hasPermission('close_of_day_config');
      if(this.level === 'Property'){        
        isEnabled = this.isCorporateCODEnabled && this.hasPermission('close_of_day_config');
      }
      return isEnabled;
    },
    isSPTToggleEnable() {
      let isEnabled = this.hasPermission('send_payment_to');
      if(this.level === 'Property'){        
        isEnabled = this.isCorporateSPTEnabled && this.hasPermission('send_payment_to');
      }
      return isEnabled;
    }
  },
  watch: {
    async property_id() {
      if (this.property_id) {
        await this.fetchPropertyData()
        this.sendPaymentToKey += 1;
        this.setDefaultProperty(this.facilityList.find(e => e.id == this.property_id));
      }
    },
    level() {
      if(this.level == 'Property'){
        this.property_id = this.defaultProperty ? this.defaultProperty.id : null;
      }
    }
  },
  methods: {
    ...mapActions({
      setFiscalYearMonth: 'settingsStore/setFiscalYearMonth',
      setDefaultProperty: 'propertiesStore/setDefaultProperty',
    }),
    ...mapMutations({
      updateCorporateCOD: "closeOfDayStore/updateCorporateCOD",
      updateCorporateSPT: "closeOfDayStore/updateCorporateSPT",
    }),
    initializeData(){
      this.panel = [];
      this.settings = [];
    },
    async fetchData() {
      try{
      const r = await api.get(this, api.SETTINGS_BILLING);
      this.tempCODSwitchValue = Number(r?.settings?.closeOfDay) ? true : false;
      this.isCodToggleUpdated = this.tempCODSwitchValue;
      this.tempSPTSwitchValue = Number(r?.settings?.sendPaymentTo?.enabled) ? true : false;
      this.isSPTToggleUpdated = this.tempSPTSwitchValue;
      this.updateCODStoreStatus(this.tempCODSwitchValue);
      this.updateSPTStoreStatus(this.tempSPTSwitchValue);
      this.settings = r.settings;
      this.settings.sendPaymentTo = r.settings.sendPaymentTo;
      this.setFiscalYearMonth(r?.settings?.fiscalYearMonth);
    }
    catch(err){
      this.showMessageNotification({ description: err }) 
    }
    },
    async fetchPropertyData(){
      try {
        const r = await api.get(this, api.SETTINGS_BILLING + `${this.property_id ? `?property_id=${this.property_id}` : ''}`)
        this.tempCODSwitchValue = Number(r.settings.closeOfDay) ? true : false;
        this.isCodToggleUpdated = this.tempCODSwitchValue;
        this.settings.closeOfDay = Number(r.settings.closeOfDay) ? true : false;
        this.tempSPTSwitchValue = Number(r.settings.sendPaymentTo?.enabled) ? true : false;
        this.isSPTToggleUpdated = this.tempSPTSwitchValue;
        this.settings.sendPaymentTo = r.settings.sendPaymentTo;
      }
      catch(err){        
       this.showMessageNotification({ description: err }) 
      }
    },
    cancelCODChange() {
      this.settings.closeOfDay = !this.tempCODSwitchValue;
      this.tempCODSwitchValue = this.settings.closeOfDay;
      this.isCodToggleUpdated = this.tempCODSwitchValue;
      this.openCloseOfDayModal = false;
    },
    async updateCODStatus() {
      await api.post(this, api.SETTINGS_BILLING + `${this.property_id ? `?property_id=${this.property_id}` : ''}`, {
        settings: { closeOfDay: this.tempCODSwitchValue || false}
      });
      this.updateCODStoreStatus(this.tempCODSwitchValue);
      this.isCodToggleUpdated = this.tempCODSwitchValue;
      this.openCloseOfDayModal = false;

    },
    cancelSPTChange() {
      this.settings.sendPaymentTo.enabled = !this.tempSPTSwitchValue;
      this.tempSPTSwitchValue = this.settings.sendPaymentTo.enabled;
      this.isSPTToggleUpdated = this.tempSPTSwitchValue;
      this.openSendPaymentToModal = false;
    },
    async updateSPTStatus() {
      await api.post(this, api.SETTINGS_BILLING + `${this.property_id ? `?property_id=${this.property_id}` : ''}`, {
        settings: { sendPaymentTo: {
          enabled: this.tempSPTSwitchValue || false,
          details: {
            ...(this.tempSPTSwitchValue && !this.property_id && ({isProperty: true}))
          }
        }
       }
      });
      this.updateSPTStoreStatus(this.tempSPTSwitchValue);
      this.isSPTToggleUpdated = this.tempSPTSwitchValue;
      this.openSendPaymentToModal = false;
      if(this.property_id){
        await this.fetchPropertyData()
      }
      else {
        await this.fetchData();
      }
      this.sendPaymentToKey += 1;
    },
    handleSPTSwitchChange(value) {
      this.tempSPTSwitchValue = value;
      if (!this.tempSPTSwitchValue) {
        this.openSendPaymentToModal = true;
      }
      else {
        this.updateSPTStatus();
      }
    },
    checkForPermission(permission, name){
      if(!this.hasPermission(permission)){
          this.showMessageNotification({ description: `You do not have permission to change ${name}.` }) 
          return;
      }
    },
    updateCODStoreStatus(value){      
      if(!this.isPropertyLevel){
          this.updateCorporateCOD(value);
        }
    },
    updateSPTStoreStatus(value){      
      if(!this.isPropertyLevel){
          this.updateCorporateSPT(value);
        }
    },
    handleCODSwitchChange(value) {
      this.tempCODSwitchValue = value;
      if (!this.tempCODSwitchValue) {
        this.openCloseOfDayModal = true;
      }
      else {
        this.updateCODStatus();
      }
    },
    save() {
      var save = {
        category: this.category,
        data: this.settings,
      };
      this.validate(this).then((status) => {
        if (!status) {
          this.errorMsg = "";
          if (this.errorHas(this.$options.name)) {
            this.errorMsg = this.errorGet(this.$options.name) + "<br>";
          }

          if (this.errors.has("offset")) {
            this.errorMsg += this.errors.first("offset") + "<br>";
          }

          if (this.errors.has("taxrate")) {
            this.errorMsg += this.errors.first("taxrate");
          }
          return;
        }
        api.post(this, api.SETTINGS, save).then((r) => {
          this.showMessageNotification({ id: this.$options.name, type: "success", description: 'Billing settings Updated' });
        });
      });
    },
    cancel() {
      this.$router.push({ path: "/settings" });
    },
    async updateSendPaymentToSetting(value){
      await api.post(this, api.SETTINGS_BILLING + `${this.property_id ? `?property_id=${this.property_id}` : ''}`, value);
      if(this.property_id){
        await this.fetchPropertyData()
      }
      else {
        await this.fetchData();
      }
      this.sendPaymentToKey += 1;
    },
    updateFiscalYearMonth(value) {
      this.settings.fiscalYearMonth = value
    }
  }
};
</script>

<style lang="scss" scoped>
.settings-billing .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px;
}
.billing-label {
  background-color: #f9fafb;
  height: 100%;
  min-height: 108px;
  width: 100%;
  color: #101318;
  display: flex;
}
</style>
